import React, { useState, useContext, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { API } from 'aws-amplify'
import { parse } from 'papaparse'
import Papa from 'papaparse'
import Hider from '../utils/Hider'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import {
  CardContent,
  Grid,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Card,
  CardHeader,
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Modal,
  Tooltip,
} from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DownloadIcon from '@mui/icons-material/Download'
import FileUploadIcon from '@mui/icons-material/FileUpload'

export default function AddEmployee({
  open,
  setOpen,
  tags,
  limitWarning,
  setLimitWarning,
  csvLimitWarning,
  setCsvLimitWarning,
}) {
  const { gStripe, gLoginEmployee, gCompanyEmployee } = useContext(
    GlobalStateContext,
  )
  const [member, setMember] = useState({
    employeeEmail: '',
    employeeName: '',
    phone: undefined,
    department: undefined,
  })
  const [tagItems, setTagItems] = useState([])
  const [csvTagItems, setCsvTagItems] = useState([])
  const [csvArray, setCsvArray] = useState([])
  const [mailChk, setMailChk] = useState(true)
  const [isPending, setIsPending] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [fileName, setFileName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataWarning, setDataWarning] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    padding: 4,
  }

  const handleSubmit = () => {
    let info = {
      ...member,
      phone: member.phone === '' ? undefined : member.phone,
      department: member.department === '' ? undefined : member.department,
    }

    API.post('Attendance', `/employee`, {
      body: { ...info, tag: tagItems, companyID: gStripe.customer.id },
    })
      .then((res) => {
        setLimitWarning(res)
        gCompanyEmployee.getEmployees(
          gStripe.customer.id,
          gLoginEmployee.loginInfo.access,
          null,
        )
        setMember({
          employeeEmail: '',
          employeeName: '',
          phone: '',
          department: '',
        })
        setTagItems([])
        setIsPending(false)
      })
      .catch((err) => console.log(err))
  }

  const handleCsvSubmit = async () => {
    // console.log('owow', csvArray)

    for (let item = 0; item < csvArray.length; item++) {
      await API.post('Attendance', `/employee`, {
        body: {
          companyID: gStripe.customer.id,
          employeeEmail: csvArray[item]['メールアドレス'],
          employeeName: csvArray[item]['ユーザー名'],
          tag: csvTagItems,
          phone:
            csvArray[item]['電話番号（任意）'] === ''
              ? undefined
              : csvArray[item]['電話番号（任意）'],
          department:
            csvArray[item]['所属（任意）'] === ''
              ? undefined
              : csvArray[item]['所属（任意）'],
        },
      })
        .then((res) => {
          setLimitWarning(res)
          gCompanyEmployee.getEmployees(
            gStripe.customer.id,
            gLoginEmployee.loginInfo.access,
            null,
          )
          setCsvTagItems([])
        })
        .catch((err) => console.log(err))
    }
    await setIsPending(false)
    await setOpenModal(false)
  }

  const downloadTemplate = () => {
    const fields = [
      'ユーザー名',
      'メールアドレス',
      '所属（任意）',
      '電話番号（任意）',
    ]
    var csv = Papa.unparse({ fields })
    var blob = new Blob([csv])
    var a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = 'SASEME_Biz_add_users_template.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  useEffect(() => {
    setDataWarning(false)
    setCsvLimitWarning(false)
  }, [gStripe.customer.id])

  return (
    <Card sx={{ mb: '15px' }}>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" sx={{ ml: '9px' }}>
              新規ユーザーを追加
            </Typography>
            <Box>
              <Button
                size="small"
                variant="outlined"
                component="label"
                startIcon={<FileUploadIcon />}
                sx={{ mr: 1 }}
              >
                CSV登録
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={async (event) => {
                    if (event.target.files[0]) {
                      setFileName(event.target.files[0].name)
                      const text = await event.target.files[0].text()
                      const data = await parse(text, {
                        header: true,
                        skipEmptyLines: true,
                      }).data
                      // 檢查 沒有資料
                      if (data.length === 0) {
                        setDataWarning(true)
                        setOpenModal(true)
                      }
                      // 檢查 是否超過上限 (csv人數 > 還可以加的人數 -> 超過上限)
                      if (
                        data.length >
                        gStripe.usage - gCompanyEmployee.employees.count
                      ) {
                        setCsvLimitWarning(true)
                        setOpenModal(true)
                      }
                      // 檢查 email, name 是否為空和email格式
                      const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
                      for (let i = 0; i < data.length; i++) {
                        if (
                          data[i]['ユーザー名'].length < 1 ||
                          data[i]['メールアドレス'].length < 1 ||
                          mailReg.test(data[i]['メールアドレス']) === false
                        ) {
                          setDataWarning(true)
                          setOpenModal(true)
                          break
                        }
                      }
                      handleOpen()
                      setCsvArray(data)
                      event.target.value = []
                    }
                  }}
                />
              </Button>
              <Button
                size="small"
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={() => {
                  downloadTemplate()
                }}
              >
                CSVダウンロード
              </Button>
              {/* {fileName} */}
              {/* 還可以用的人數 {gStripe.usage - gCompanyEmployee.employees.count} */}
              <IconButton
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <Hider show={open}>
                  <KeyboardArrowUpIcon />
                  <KeyboardArrowDownIcon />
                </Hider>
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CardContent sx={{ paddingBottom: 'unset' }}>
        <Grid
          container
          alignItems="top"
          spacing={1}
          sx={{ mt: '2px', p: '0px' }}
        >
          <Grid item xs={6}>
            <TextField
              size="small"
              label="ユーザー名"
              variant="outlined"
              fullWidth
              required
              value={member.employeeName}
              onChange={(e) => {
                setMember({ ...member, employeeName: e.target.value })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="メールアドレス"
              variant="outlined"
              error={!mailChk}
              helperText={
                mailChk ? '' : 'メールアドレスの形式が正しくありません'
              }
              fullWidth
              required
              value={member.employeeEmail}
              onChange={(e) => {
                setMember({ ...member, employeeEmail: e.target.value })
                const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
                setMailChk(mailReg.test(e.target.value))
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              label="所属（任意）"
              variant="outlined"
              fullWidth
              value={member.department}
              onChange={(e) => {
                setMember({ ...member, department: e.target.value.trim() })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="電話番号（任意）"
              variant="outlined"
              fullWidth
              value={member.phone}
              onChange={(e) => {
                setMember({ ...member, phone: e.target.value.trim() })
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ display: 'center', alignItems: 'center' }}>
              <FormLabel sx={{ mr: '15px' }} required>
                ロール
              </FormLabel>
              <FormGroup
                sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}
              >
                {tags
                  .filter((i) =>
                    gLoginEmployee.loginInfo.access.includes('ロール管理')
                      ? i.tag !== 'オーナー'
                      : i.tag !== 'オーナー' && i.tag !== 'マネージャー',
                  )
                  .sort((a, b) => b.access.length - a.access.length)
                  .map((i) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              let index = tagItems.indexOf(e.target.value)
                              if (e.target.value === 'ゲスト') {
                                if (index === -1) {
                                  setTagItems([e.target.value])
                                } else {
                                  setTagItems([])
                                }
                              } else {
                                if (index === -1) {
                                  setTagItems(
                                    [...tagItems, e.target.value].filter(
                                      (tag) => tag !== 'ゲスト',
                                    ),
                                  )
                                } else {
                                  setTagItems(
                                    tagItems.filter(
                                      (tag) => tag !== e.target.value,
                                    ),
                                  )
                                }
                              }
                            }}
                            checked={tagItems.includes(i.tag)}
                          />
                        }
                        label={i.tag}
                        value={i.tag}
                      />
                    )
                  })}
              </FormGroup>
            </FormControl>
            {gLoginEmployee.loginInfo.access && (
              <Hider
                show={gLoginEmployee.loginInfo.access.includes('ロール管理')}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(`/employees/tag`)
                  }}
                >
                  <AddCircleIcon fontSize="inherit" />
                </IconButton>
              </Hider>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              disabled={
                member.employeeName === '' ||
                member.employeeEmail === '' ||
                tagItems.length < 1 ||
                mailChk !== true ||
                limitWarning === true
              }
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
                setIsPending(true)
              }}
            >
              登録
            </Button>
            <Hider show={limitWarning}>
              <Typography
                variant="h6"
                sx={{ color: 'error.main', textDecoration: 'underline' }}
                component={Link}
                to={'/settings'}
              >
                ユーザー登録数が上限に達しました。
                {gStripe.subscription.items.data[0].plan.id ===
                'price_1LkMnTG8znjDYwn9HXn1ytpU'
                  ? '設定ページでプランのアップグレードを行ってください。'
                  : '設定ページでユーザーの追加購入をお願いします。'}
              </Typography>
            </Hider>
          </Grid>
        </Grid>
      </CardContent>

      <Hider show={isPending}>
        <CircularProgress
          sx={{
            position: 'fixed',
            margin: 'auto auto',
            zIndex: '101',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          color="primary"
        />
      </Hider>

      <Modal open={openModal} onClose={handleClose}>
        <Box sx={style}>
          <Hider show={dataWarning}>
            <Box>
              {/* 上傳的csv有缺少資料 */}
              <Typography>
                有効なユーザー名およびメールアドレスを入力して下さい。いずれも空欄での登録はできません。
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: '10px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: '10px' }}
                  onClick={() => {
                    setOpenModal(false)
                    setAnchorEl(null)
                    setDataWarning(false)
                    setFileName('')
                  }}
                >
                  確定
                </Button>
              </Box>
            </Box>

            <Hider show={isPending}>
              <Box
                sx={{
                  ...style,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '168.75px',
                }}
              >
                <CircularProgress />
              </Box>

              <Hider show={csvLimitWarning}>
                {/* 到達登入上限的警告 */}
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    <span>
                      <WarningIcon
                        sx={{
                          transform: 'translateY(5px)',
                          color: 'error.main',
                        }}
                      />
                    </span>
                    ユーザー登録数が上限に達しました。
                  </Typography>
                  <Typography>
                    {gStripe.subscription.items.data[0].plan.id ===
                    'price_1LkMnTG8znjDYwn9HXn1ytpU'
                      ? '設定ページでプランのアップグレードを行ってください。'
                      : '設定ページでユーザーの追加購入をお願いします。'}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: '10px',
                    }}
                  >
                    <Button
                      size="small"
                      sx={{ mr: '10px' }}
                      onClick={() => {
                        setCsvTagItems([])
                        setOpenModal(false)
                        setCsvLimitWarning(false)
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setCsvLimitWarning(false)
                        navigate('/settings')
                      }}
                    >
                      設定ページへ移動
                    </Button>
                  </Box>
                </Box>

                <Box>
                  {/* 確認上傳 */}
                  <Typography>
                    「{fileName}
                    」内のユーザーのロールは以下の選択肢から選択してください。
                  </Typography>
                  <FormControl
                    sx={{
                      display: 'center',
                      alignItems: 'center',
                      pr: '32px',
                    }}
                  >
                    <FormLabel sx={{ mr: '15px' }} required>
                      ロール
                    </FormLabel>
                    <FormGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        mr: '47px',
                      }}
                    >
                      {/* ///// */}
                      {tags
                        .filter((i) =>
                          gLoginEmployee.loginInfo.access.includes('ロール管理')
                            ? i.tag !== 'オーナー'
                            : i.tag !== 'オーナー' && i.tag !== 'マネージャー',
                        )
                        .sort((a, b) => b.access.length - a.access.length)
                        .map((i) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    let index = csvTagItems.indexOf(
                                      e.target.value,
                                    )
                                    if (e.target.value === 'ゲスト') {
                                      if (index === -1) {
                                        setCsvTagItems([e.target.value])
                                      } else {
                                        setCsvTagItems([])
                                      }
                                    } else {
                                      if (index === -1) {
                                        setCsvTagItems(
                                          [
                                            ...csvTagItems,
                                            e.target.value,
                                          ].filter((tag) => tag !== 'ゲスト'),
                                        )
                                      } else {
                                        setCsvTagItems(
                                          csvTagItems.filter(
                                            (tag) => tag !== e.target.value,
                                          ),
                                        )
                                      }
                                    }
                                  }}
                                  checked={csvTagItems.includes(i.tag)}
                                />
                              }
                              label={i.tag}
                              value={i.tag}
                            />
                          )
                        })}
                    </FormGroup>
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: '10px',
                    }}
                  >
                    <Button
                      size="small"
                      sx={{ mr: '10px' }}
                      onClick={() => {
                        setOpenModal(false)
                        setAnchorEl(null)
                        setFileName('')
                        setCsvTagItems([])
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault()
                        handleCsvSubmit()
                        setIsPending(true)
                        setFileName('')
                      }}
                    >
                      新規ユーザーを追加
                    </Button>
                  </Box>
                </Box>
              </Hider>
            </Hider>
          </Hider>
        </Box>
      </Modal>
    </Card>
  )
}

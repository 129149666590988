import React, { useEffect, useState, useContext } from 'react'
import { API } from 'aws-amplify'
import { navigate } from 'gatsby'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import Hider from '../../components/utils/Hider'
import AddEmployee from '../../components/attendance/addEmployee'
import AccessCheck from '../../components/attendance/accessCheck'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  Typography,
  Grid,
  Collapse,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

export default function Employees({ location }) {
  const { gStripe, gCompanyEmployee, gLoginEmployee } = useContext(
    GlobalStateContext,
  )
  const [open, setOpen] = useState(false)
  const [del, setDel] = useState(false)
  const [tags, setTags] = useState([])
  const [limitWarning, setLimitWarning] = useState(false)
  const [csvLimitWarning, setCsvLimitWarning] = useState(false)
  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: '#28aeb1',
        },
        info: {
          light: '#cccccc',
          main: '#666666',
        },
        error: {
          light: '#db807c',
          main: '#CC4A44',
        },
      },
      typography: {
        fontFamily: "'Noto Sans JP', sans-serif",
        h2: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '16px',
        },
        h5: {
          fontSize: '14px',
        },
        button: {
          textTransform: 'none',
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: '0.02rem',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        },
      },
    })

  const deleteEmployees = async (rowsSelected) => {
    gCompanyEmployee.setIsPending(true)
    for (let i = 0; i < rowsSelected.length; i++) {
      await API.del('Attendance', `/employee`, {
        body: {
          companyID: gStripe.customer.id,
          subUUID: gCompanyEmployee.employees.Items[rowsSelected[i]].subUUID,
        },
      })
        .then((res) => {
          gCompanyEmployee.getEmployees(
            gStripe.customer.id,
            gLoginEmployee.loginInfo.access,
            null,
          )
        })
        .catch((err) => console.log(err))
    }
    setLimitWarning(false)
    setCsvLimitWarning(false)
  }

  useEffect(() => {
    if (gStripe.customer.id) {
      API.get('Attendance', `/tag`, {
        queryStringParameters: { companyID: gStripe.customer.id },
      })
        .then((res) => {
          setTags(res)
        })
        .catch((err) => console.log(err))

      setLimitWarning(false)
    }
  }, [gStripe.customer.id])

  return (
    <Layout location={location}>
      <AccessCheck access="ユーザー">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Collapse in={open}>
              <AddEmployee
                open={open}
                setOpen={setOpen}
                tags={tags}
                setLimitWarning={setLimitWarning}
                limitWarning={limitWarning}
                csvLimitWarning={csvLimitWarning}
                setCsvLimitWarning={setCsvLimitWarning}
              />
            </Collapse>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={
                  <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                    ユーザー 一覧
                  </Typography>
                }
                data={gCompanyEmployee.employees.Items}
                columns={[
                  {
                    name: 'employeeName',
                    label: 'ユーザー名',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'employeeEmail',
                    label: 'メールアドレス',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'department',
                    label: '所属',
                    options: {
                      filter: true,
                      filterType: 'dropdown',
                      customBodyRender: (value) => {
                        return (
                          <Typography
                            variant="h5"
                            sx={{
                              color:
                                value === undefined ? 'info.light' : 'black',
                            }}
                          >
                            {value === undefined ? '未設定' : value}
                          </Typography>
                        )
                      },
                    },
                  },
                  {
                    name: 'phone',
                    label: '電話番号',
                    options: {
                      filter: false,
                      customBodyRender: (value) => {
                        return (
                          <Typography
                            variant="h5"
                            sx={{
                              color:
                                value === undefined ? 'info.light' : 'black',
                            }}
                          >
                            {value === undefined ? '未設定' : value}
                          </Typography>
                        )
                      },
                    },
                  },

                  {
                    name: 'tag',
                    label: 'ロール',
                    options: {
                      filter: true,
                      filterType: 'dropdown',
                      sort: false,
                      customBodyRender: (
                        value,
                        tableMeta,
                        updateValue,
                        dataIndex,
                      ) => {
                        let row =
                          gCompanyEmployee.employees.Items[tableMeta.rowIndex]
                        return (
                          <>
                            {tableMeta.rowData[4].map((tag, index) => {
                              return <Chip label={tag} style={{ margin: 1 }} />
                            })}
                          </>
                        )
                      },
                    },
                  },
                ]}
                style={{
                  '> tr.MuiTableRow-root': {
                    color: 'red',
                  },
                  color: 'red',
                }}
                options={{
                  onRowClick: (rowData, rowMeta) => {
                    if (typeof window !== 'undefined') {
                      localStorage.setItem(
                        'currEmployee',
                        gCompanyEmployee.employees.Items[rowMeta.dataIndex] 
                          .subUUID,
                      )
                      navigate('/employees/employeesInfo')
                    }
                  },
                  textLabels: {
                    body: {
                      noMatch: '検索結果は０件です',
                      toolTip: 'ソート',
                      columnHeaderTooltip: (column) => `ソート ${column.label}`,
                    },
                    pagination: {
                      next: 'Next Page',
                      previous: 'Previous Page',
                      rowsPerPage: '表示数',
                      displayRows: '/',
                    },
                    toolbar: {
                      search: '検索',
                      downloadCsv: 'CSV',
                      print: '印刷する',
                      viewColumns: '列を表示',
                      filterTable: 'フィルター',
                    },
                    viewColumns: {
                      title: '列を表示',
                      titleAria: 'Show/Hide Table Columns',
                    },
                    filter: {
                      all: '全て',
                      title: 'フィルター',
                      reset: 'リセット',
                    },
                    selectedRows: {
                      text: '選択済み',
                      delete: '削除',
                      deleteAria: 'Delete Selected Rows',
                    },
                  },
                  setTableProps: () => {
                    return { fullWidth: true, size: 'small' }
                  },
                  filterType: 'checkbox',
                  elevation: 0,
                  selectableRows: del ? 'multiple' : 'none',
                  isRowSelectable: (dataIndex, selectedRows, data) => {
                    return gCompanyEmployee.employees.Items[
                      dataIndex
                    ].tag.includes('オーナー')
                      ? false
                      : true
                  },
                  customToolbarSelect: (
                    selectedRows,
                    displayData,
                    setSelectedRows,
                  ) => (
                    <div style={{ marginRight: '24px' }}>
                      <Button
                        sx={{ color: 'error.main' }}
                        size="small"
                        onClick={() => {
                          deleteEmployees(
                            selectedRows.data.map((i) => i.dataIndex),
                          )
                          setLimitWarning(false)
                          setDel(false)
                          setSelectedRows([])
                        }}
                      >
                        ユーザーを削除
                      </Button>
                    </div>
                  ),
                  customToolbar: () => {
                    return (
                      <>
                        <Tooltip title="ロール管理">
                          <IconButton
                            component={Link}
                            to={`/employees/tag`}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                          >
                            <ManageAccountsIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            open === true
                              ? '新規ユーザー追加をキャンセル'
                              : '新規ユーザーを追加'
                          }
                        >
                          <IconButton
                            sx={{ '&:hover': { color: 'primary.main' } }}
                            onClick={() => {
                              setOpen(!open)
                            }}
                          >
                            <Hider show={open}>
                              <CloseIcon />
                              <PersonAddAlt1Icon />
                            </Hider>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="ユーザーを削除">
                          <IconButton
                            sx={{
                              color: del ? 'error.main' : '',
                              '&:hover': { color: 'error.main' },
                            }}
                            onClick={() => {
                              setDel(!del)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  },
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </AccessCheck>
    </Layout>
  )
}
